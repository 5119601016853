import { useCallback } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import {
  addSceneMutation,
  getSceneWithElementsQuery,
  updateLinkMediumToSceneMutation,
  updateSceneDetailsMutation,
  updateSceneSettingsMutation,
  updateUnlinkMediumToSceneMutation,
} from '../../../apollo/query/scenes'
import { handleApolloError } from '../../../utils/errors'
import useSelectedNode from './useSelectedNode'
import { useRemoveNewNodePlaceholder, setNode } from '../helpers/nodeHelper'
import { useReactFlow } from 'reactflow'
import useAddUpdateElement from './useAddUpdateElement'
import { newEdgeId, removeHiddenEdges } from '../helpers/edgeHelper'

const NO_SCENE_TITLE = '__NO_TITLE__'

const getSceneName = (scene) => {
  if (scene.name === NO_SCENE_TITLE) return `Scene ${scene.number}`
  return scene.name
}

const useAddUpdateScene = () => {
  const removeNewNodePlaceholder = useRemoveNewNodePlaceholder()
  const reactFlow = useReactFlow()
  const { getEdges, getNodes, addNodes, addEdges, setNodes, getNode } =
    reactFlow
  const selectedNode = useSelectedNode()

  const { updateElement } = useAddUpdateElement()
  const [_updateSceneDetails] = useMutation(updateSceneDetailsMutation, {
    onError: handleApolloError,
  })
  const updateSceneDetails = useCallback(
    async (variables, newNode) => {
      let node = newNode ?? selectedNode
      variables.description =
        variables.description ?? node.data.description ?? ''
      variables.name = variables.name ?? node.data.name
      variables.canvasX = variables.canvasX ?? node.position.x
      variables.canvasY = variables.canvasY ?? node.position.y
      variables.start = variables.start ?? node.data.start

      setTimeout(() => {
        setNode(reactFlow, {
          ...node,
          position: { x: variables.canvasX, y: variables.canvasY },
          data: {
            ...node.data,
            ...variables,
          },
        })
      }, 200);

      if (variables.name === '') {
        const {
          data: {
            updateSceneDetails: { scene },
          },
        } = await _updateSceneDetails({
          variables: {
            ...node.data,
            ...variables,
            name: NO_SCENE_TITLE,
          },
        })
        return updateSceneDetails({
          ...variables,
          name: getSceneName(scene),
        })
      }

      return _updateSceneDetails({
        variables: {
          ...node.data,
          ...variables,
        },
      })
    },
    [selectedNode]
  )

  const [_addScene] = useMutation(addSceneMutation, {
    onError: handleApolloError,
    onCompleted: async ({ addScene: { scene: _scene } }, { variables }) => {
      const scene = { ..._scene }
      const newNode = {
        id: scene.id,
        position: { x: scene.canvasX, y: scene.canvasY },
        type: 'Scene',
        data: {
          ...scene,
          name: getSceneName(scene),
          elements: [],
          filming: variables.filming,
        },
        selected: variables.selected,
      }
      const connectedEdge = getEdges().find((e) => e.id === newEdgeId)
      removeNewNodePlaceholder()
      addNodes([newNode])

      if (scene.name === NO_SCENE_TITLE) scene.name = getSceneName(scene)

      if (variables.description) {
        delete scene.description
        scene.description = variables.description
      }
      
      await updateSceneDetails({ ...scene }, newNode)

      if (connectedEdge) {
        const sourceElId = connectedEdge.sourceHandle.split('-')[1]
        const randomizeIndex = connectedEdge.sourceHandle.split('-')[2]
        const sourceNode = getNodes().find((n) => n.id === connectedEdge.source)
        const sourceEl = sourceNode.data.elements.find(
          (e) => e.id === sourceElId
        )
        const newElement = { ...sourceEl }
        if (newElement.randomizedLinkToIds.length) {
          newElement.randomizedLinkToIds[randomizeIndex] = scene.id
        } else newElement.linkToId = scene.id

        setTimeout(() => {
          updateElement({
            variables: newElement,
          }).then(() => {
            addEdges([
              {
                ...connectedEdge,
                id: `${sourceElId}${randomizeIndex ? `-${randomizeIndex}` : ''}`,
                target: scene.id,
                targetHandle: `e${scene.id}-${sourceElId}${randomizeIndex ? `-${randomizeIndex}` : ''}`,
              },
            ])
          })
        }, 100)
      }

      removeHiddenEdges(reactFlow)
    },
  })
  const addScene = useCallback(async (variables) => {
    variables = {
      ...variables,
      canvasX: parseInt(variables.canvasX),
      canvasY: parseInt(variables.canvasY),
      start: getNodes().filter((n) => n.type === 'Scene').length === 0,
    }
    if (variables.name === '') {
      return _addScene({
        variables: {
          ...variables,
          name: NO_SCENE_TITLE,
        },
      }).then(
        ({
          data: {
            addScene: { scene },
          },
        }) => scene
      )
    }

    return _addScene({
      variables,
    }).then(
      ({
        data: {
          addScene: { scene },
        },
      }) => scene
    )
  }, [])

  const updateNode = (variables) => {
    setNode(reactFlow, {
      ...selectedNode,
      data: {
        ...selectedNode.data,
        ...variables,
      },
    })
  }
  const [_updateSceneSettings] = useMutation(updateSceneSettingsMutation, {
    onError: handleApolloError,
    onCompleted: ({ updateSceneSettings: { scene } }) => {
      updateNode(scene)
    },
  })
  const updateSceneSettings = (variables) => {
    updateNode(variables)
    return _updateSceneSettings({
      variables,
    })
  }

  const [reloadScene] = useLazyQuery(getSceneWithElementsQuery, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ scene }) => {
      const node = getNode(scene.id)
      setNode(reactFlow, {
        ...node,
        data: {
          ...node.data,
          ...scene,
        },
      })
    },
  })
  const [_updateLinkMediumToScene] = useMutation(
    updateLinkMediumToSceneMutation,
    {
      onError: handleApolloError,
      onCompleted: () => {
        reloadScene({
          variables: {
            id: selectedNode.id,
          },
        })
      },
    }
  )

  const updateLinkMediumToScene = (mediumId, sceneId) => {
    return _updateLinkMediumToScene({
      variables: {
        mediumId,
        sceneId: sceneId,
      },
    })
  }

  const [_updateUnlinkMediumToScene] = useMutation(
    updateUnlinkMediumToSceneMutation,
    {
      onError: handleApolloError,
      onCompleted: () => {
        reloadScene({
          variables: {
            id: selectedNode.id,
          },
        })
      },
    }
  )

  const updateUnlinkMediumToScene = (mediumId) => {
    return _updateUnlinkMediumToScene({
      variables: {
        mediumId,
        sceneId: selectedNode.id,
      },
    })
  }

  const handleMediaDeletionUpdates = (mediumIds) => {
    setNodes(
      getNodes().map((n) =>
        mediumIds.includes(n.data.video?.id)
          ? { ...n, data: { ...n.data, hasVideo: null, video: null } }
          : n
      )
    )
  }

  return {
    addScene,
    reloadScene,
    updateSceneDetails,
    updateSceneSettings,
    updateLinkMediumToScene,
    updateUnlinkMediumToScene,
    handleMediaDeletionUpdates,
  }
}

export default useAddUpdateScene
