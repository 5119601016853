import React, { useState, useEffect } from 'react'
import { getRotation } from '../../../Editor/helpers/controls'
import TextBubble from '../../../Editor/Video/Elements/partials/TextBubble'
import Button from '../../../Editor/Video/Elements/partials/Button'
import useHasHotspot from '../../../FlowV2/hooks/useHasHotspot'

const MultipleChoice = ({ id, element, state, childElements }) => {
  const [loading, setLoading] = useState(true)
  const [buttons, setButtons] = useState([])
  const hasHotspot = useHasHotspot()

  let BOX_WIDTH = 2
  let BUBBLE_WIDTH = 1.8
  let BUTTON_WIDTH = 2.75
  if (element.labelSize === 'WIDE') {
    BUBBLE_WIDTH *= 2
    BOX_WIDTH *= 2
  }
  if (childElements[0].labelSize === 'WIDE') BUTTON_WIDTH *= 2

  const getMpcAnswers = () => {
    const btns = childElements.map((chEl, index) => (
      <Button
        type="mpc"
        key={`btn-${index}`}
        textValue={chEl.label.trim() || 'Answer'}
        id={`Answer-${index}`}
        width={BUTTON_WIDTH}
      />
    ))
    setButtons(btns)
    if (loading && btns) setLoading(false)
  }
  useEffect(() => {
    if (element) {
      setLoading(true)
      getMpcAnswers()
    }
  }, [element, childElements])

  return (
    <a-entity
      className={state.showElements ? '' : 'hidden'}
      hideable-element={`visible: ${state.showElements}`}
      rotation={`${getRotation({ x: element.anchorX, y: element.anchorY })} 0`}>
      <a-collision-box
        id={id}
        rotation="0 -5 0"
        width={BOX_WIDTH}
        scroll-header-offset={hasHotspot ? 315 : 180}>
        <TextBubble
          type="mpc"
          textValue={element.label || 'Question'}
          id="sceneQuestion"
          width={BUBBLE_WIDTH}
          bottomSpacing={0.1}
        />
        <a-entity
          id="mpc-list"
          mpc={`elementSize: ${element.labelSize}; answerElementSize: ${childElements[0].labelSize}`}>
          {buttons}
        </a-entity>
      </a-collision-box>
    </a-entity>
  )
}
export default MultipleChoice
