import React, { useEffect, useState } from 'react'
import Modal from '../../../../UI/Modal'

const MigrateModal = ({
  id,
  appendTo,
  onConfirm,
  scenarioName,
  onClose = () => {},
}) => {
  const [single, setSingle] = useState(
    $('#migrateModal').data('all') === 'false'
  )

  useEffect(() => {
    setSingle($('#migrateModal').data('all') === 'false')
  }, [scenarioName])
  return (
    <Modal
      id={id}
      appendTo={appendTo}
      headerText={
        single
          ? 'Update this scenario to the new flow'
          : 'Update all scenarios to the new flow'
      }
      overflowY="visible"
      width={600}
      closeOnEscape
      footerText="Managers, publishers and writers in this workspace will be notified by email."
      buttons={
        <div className="flex-container align-middle">
          <button
            className="hollow button secondary o-button--auto-width"
            data-close={id}>
            Cancel
          </button>
          <button
            type="submit"
            className="button primary o-button--auto-width"
            data-close={id}
            onClick={onConfirm}>
            Confirm
          </button>
        </div>
      }
      onOpen={(e, d) => {
        $('#migrateModal').data()
      }}
      onClose={onClose}>
      <div>
        <p className="text-dark mt-2 mb-2">
          The new flow makes creating highly engaging immersive scenarios even
          easier, featuring simplified navigation, the ability to combine
          elements, and much more.
          <br />
          <a
            href="https://www.warpvr.com/product-updates/now-even-more-creative-freedom-with-the-new-flow"
            target="blank"
            rel="noreferrer"
            className="text-underline text-dark">
            Watch this product demo to discover more.
          </a>
        </p>
        {single && (
          <p className="border-assertive p-2 text-dark border-radius">
            You are updating the scenario '{scenarioName}' to the new flow.
            Scenarios that have not been updated, will still open in the legacy
            flow.
          </p>
        )}
        <p className="border-assertive p-2 text-dark border-radius mt-2">
          Scenarios created with the new Flow cannot be played on a Pico G2 4K
          or Meta Quest 1 VR headset. This action is irreversible.
        </p>
      </div>
    </Modal>
  )
}
export default MigrateModal
