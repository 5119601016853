import { gql } from '@apollo/client'

const getWorkspacesQuery = gql`
  query getWorkspaces {
    workspaces {
      id
      name
      workspaceKind
      workspaceManagedByClientName
      publishedScenariosCount
      originalScenariosCount
      ownerName
      createdAt
    }
  }
`

const addWorkspaceMutation = gql`
  mutation addWorkspaceMutation(
    $name: String!
    $kind: WorkspaceKind!
    $ownerFirstName: String
    $ownerLastName: String
    $ownerEmail: String
    $ownerEmployeeNr: String
  ) {
    addWorkspace(
      name: $name
      kind: $kind
      ownerFirstName: $ownerFirstName
      ownerLastName: $ownerLastName
      ownerEmail: $ownerEmail
      ownerEmployeeNr: $ownerEmployeeNr
    ) {
      client {
        name
      }
    }
  }
`

const updateWorkspaceMutation = gql`
  mutation updateWorkspaceMutation(
    $id: ID!
    $name: String!
    $kind: WorkspaceKind!
  ) {
    updateWorkspace(id: $id, name: $name, kind: $kind) {
      client {
        name
      }
    }
  }
`

const removeWorkspaceMutation = gql`
  mutation removeWorkspace($id: ID!) {
    removeWorkspace(id: $id) {
      id
    }
  }
`

const adminAddClientBillingMutation = gql`
  mutation adminAddClientBilling(
    $name: String!
    $kind: WorkspaceKind!
    $ownerFirstName: String
    $ownerLastName: String
    $ownerEmail: String
    $ownerEmployeeNr: String
    $features: [Feature!]!
    $planId: ID
    $planBillingPeriod: BillingPeriod
    $planBillingCurrency: BillingCurrency
    $planAdditionalWorkspaces: Int
    $planAdditionalPublishedScenarios: Int
  ) {
    addClient(
      name: $name
      kind: $kind
      ownerFirstName: $ownerFirstName
      ownerLastName: $ownerLastName
      ownerEmail: $ownerEmail
      ownerEmployeeNr: $ownerEmployeeNr
      features: $features
      planId: $planId
      planBillingPeriod: $planBillingPeriod
      planBillingCurrency: $planBillingCurrency
      planAdditionalWorkspaces: $planAdditionalWorkspaces
      planAdditionalPublishedScenarios: $planAdditionalPublishedScenarios
    ) {
      client {
        name
      }
    }
  }
`

const adminAddClientDistributionMutation = gql`
  mutation adminAddClientDistribution(
    $name: String!
    $kind: WorkspaceKind!
    $managedByClientId: ID!
    $ownerFirstName: String
    $ownerLastName: String
    $ownerEmail: String
    $ownerEmployeeNr: String
    $features: [Feature!]!
  ) {
    addClient(
      name: $name
      kind: $kind
      managedByClientId: $managedByClientId
      ownerFirstName: $ownerFirstName
      ownerLastName: $ownerLastName
      ownerEmail: $ownerEmail
      ownerEmployeeNr: $ownerEmployeeNr
      features: $features
    ) {
      client {
        name
      }
    }
  }
`

const adminUpdateClientBillingMutation = gql`
  mutation adminUpdateClientBilling(
    $id: ID!
    $name: String!
    $kind: WorkspaceKind!
    $features: [Feature!]!
    $planId: ID
    $planBillingPeriod: BillingPeriod
    $planBillingCurrency: BillingCurrency
    $planAdditionalWorkspaces: Int
    $planAdditionalPublishedScenarios: Int
  ) {
    updateClient(
      id: $id
      name: $name
      kind: $kind
      features: $features
      planId: $planId
      planBillingPeriod: $planBillingPeriod
      planBillingCurrency: $planBillingCurrency
      planAdditionalWorkspaces: $planAdditionalWorkspaces
      planAdditionalPublishedScenarios: $planAdditionalPublishedScenarios
    ) {
      client {
        name
      }
    }
  }
`

const adminUpdateClientDistributionMutation = gql`
  mutation adminUpdateClientDistribution(
    $id: ID!
    $name: String!
    $kind: WorkspaceKind!
    $managedByClientId: ID!
    $features: [Feature!]!
  ) {
    updateClient(
      id: $id
      name: $name
      kind: $kind
      managedByClientId: $managedByClientId
      features: $features
    ) {
      client {
        name
      }
    }
  }
`

const getAdminClientsQuery = gql`
  query adminClients {
    clients {
      id
      name
      ownerName
      lastActivityAt
      createdAt
      workspaceKind
      workspaceManagedByClientName
      plan {
        id
        name
      }
      planBillingPeriod
      planBillingCurrency
      planAdditionalPublishedScenarios
      planAdditionalWorkspaces
      features
      statistics {
        attemptCount
        lastMonthAttemptCount
        testAttemptCount
        lastMonthTestAttemptCount
        last3mActiveTraineeCount
        traineeCount
        memberCount
        enrolledTraineeCount
        groupCount
        courseCount
        originalSceneCount
        publishedSceneCount
        draftScenarioCount
        suspendedScenarioCount
        publishedScenarioCount
        testScenarioCount
        mediumCount
        originalStorageByteSize
        publishedStorageByteSize
        lastMonthDraftSceneCount
        lastWeekDraftSceneCount
        lastMonthPublishedScenarioCount
        lastMonthMediumCount
        lastWeekActiveMemberCount
        lastMonthActiveMemberCount
      }
    }
  }
`

const changeOwnerMutation = gql`
  mutation changeOwner($newOwnerId: ID!) {
    changeOwner(newOwnerId: $newOwnerId) {
      client {
        name
      }
    }
  }
`

const getWorkspaceHistoryQuery = gql`
  query getWorkspaceHistory($sceneId: ID, $scenarioId: ID) {
    activities(sceneId: $sceneId, scenarioId: $scenarioId) {
      id
      user {
        name
        administrator
        avatar {
          medium
        }
        role
      }
      action
      description
      createdAt
      metadata {
        key
        value
      }
    }
  }
`

export {
  getWorkspacesQuery,
  addWorkspaceMutation,
  updateWorkspaceMutation,
  removeWorkspaceMutation,
  adminAddClientBillingMutation,
  adminAddClientDistributionMutation,
  adminUpdateClientBillingMutation,
  getAdminClientsQuery,
  adminUpdateClientDistributionMutation,
  changeOwnerMutation,
  getWorkspaceHistoryQuery,
}
