import { useQuery } from '@apollo/client'
import React from 'react'
import withApollo from '../../hooks/withApollo'
import ActivitiesTable from '../../components/Activity/ActivitiesTable'
import { getActivitiesQuery } from '../../apollo/query/activities'

const Activities = () => {
  const { loading, data, error } = useQuery(getActivitiesQuery)
  if (error) return null
  
  return (
    <ActivitiesTable
      data={data}
      error={error}
      loading={loading}
      title="Workspace history"
      exportTitle="Export activity log"
    />
  )
}

export default withApollo(Activities)
