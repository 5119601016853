import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReactFlow, {
  Background,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  useNodesInitialized,
  useUpdateNodeInternals,
  useOnViewportChange,
} from 'reactflow'
import { useMutation } from '@apollo/client'
import 'reactflow/dist/style.css'
import useSelectedEdges from '../../hooks/useSelectedEdges'
import { ScenarioEditorContext } from '../../context/ScenarioEditorProvider'
import { updateSceneDetailsMutation } from '../../../../apollo/query/scenes'
import { handleApolloError } from '../../../../utils/errors'
import useAddUpdateElement from '../../hooks/useAddUpdateElement'
import {
  getElementFromSourceHandle,
  getPointColor,
} from '../../helpers/elementHelper'
import {
  useAddNewNodePlaceholder,
  getHandles,
  newNodeId,
  useRemoveNewNodePlaceholder,
  scenesToNodes,
  setCentralNodeAndSelectIt,
  getConnectedNodes,
  setNode,
  getNodeHandles,
} from '../../helpers/nodeHelper'
import {
  newEdgeId,
  removeEdge,
  unSelectHoverEdges,
} from '../../helpers/edgeHelper'
import useDoubleClick from '../../../../hooks/useDoubleClick'
import { nodeTypes } from './Node'
import { edgeTypes } from './Edge'
import config from '../../config/config'
import FlowControls from '../FlowControls'
import {
  fitView,
  getCanvasState,
  saveCanvasState,
} from '../../helpers/controlsHelper'
import { useLocation, useNavigate } from 'react-router'
import useShowVideoEditor from '../../hooks/useShowVideoEditor'
import useSelectedNode, {
  useAllSelectedNodes,
} from '../../hooks/useSelectedNode'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { EDITOR_TABS } from '../../helpers/editorHelper'
import useFlowKeyboardShortcuts from '../../hooks/useFlowKeyboardShortcuts'
import useAddUpdateScene from '../../hooks/useAddUpdateScene'

const SCENES_PATH_REGEX = /\/scenes\/(\d+).*$/

const FlowDiagram = ({ visible }) => {
  const { scenario } = useContext(ScenarioEditorContext)
  const connectingNodeId = useRef(null)
  const diagramRef = useRef(null)
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const reactFlow = useReactFlow()
  const { screenToFlowPosition } = reactFlow
  const selectedEdges = useSelectedEdges()
  const [fittedView, setFittedView] = useState()
  const nodesInitialized = useNodesInitialized()
  const updateNodeInternals = useUpdateNodeInternals()
  const navigate = useNavigate()
  const location = useLocation()
  const showVideoEditor = useShowVideoEditor()
  const selectedNode = useSelectedNode()
  const addNewNodePlaceholder = useAddNewNodePlaceholder()
  const removeNewNodePlaceholder = useRemoveNewNodePlaceholder()
  const showFormEditor = useMemo(
    () =>
      !EDITOR_TABS.some(
        (tab) => tab.isRoute && location.pathname.includes(tab.key)
      ),
    [location.pathname]
  )

  const [connectionLineStyle, setConnectionLineStyle] = useState({
    strokeWidth: 2,
  })
  const previousSelectedNode = useRef(null)

  const { updateSceneDetails } = useAddUpdateScene()
  const { updateElement } = useAddUpdateElement()
  const selectedNodes = useAllSelectedNodes()
  const updateDocumentTitle = useDocumentTitle()
  const { checkAndReorderElements } = useAddUpdateElement()

  const onConnect = useCallback(
    (params) => {
      // reset the start node on connections
      connectingNodeId.current = null
      if (params.source === params.target) return
      if (params.sourceHandle && params.targetHandle) {
        const allHandles = getHandles(nodes, selectedEdges)

        const sourceHandle = allHandles.source.find(
          (h) => h.id === params.sourceHandle
        )
        const { isRandomized } = sourceHandle

        const sourceNode = nodes.find((n) => n.id === params.source)
        const sourceElement = {
          ...sourceNode.data.elements.find(
            (e) => e.id === params.sourceHandle.split('-')[1]
          ),
        }

        let newEdge = {
          id: sourceElement.id,
          source: params.source,
          target: params.target,
          type: 'default-edge',
          sourceHandle: sourceHandle.id,
          targetHandle: `e${params.target}-${sourceElement.id}`,
          updatable: 'target',
          data: { points: sourceElement.points },
        }
        if (isRandomized) {
          const { randomizeIndex } = sourceHandle
          if (!sourceElement.randomizedLinkToIds)
            sourceElement.randomizedLinkToIds = []
          else
            sourceElement.randomizedLinkToIds = [
              ...sourceElement.randomizedLinkToIds,
            ]
          sourceElement.randomizedLinkToIds[randomizeIndex] = params.target
          sourceElement.linkToId = null
          sourceElement.linkToEnding = false
          newEdge = {
            ...newEdge,
            id: `${sourceElement.id}-${randomizeIndex}`,
            source: params.source,
            target: params.target,
            sourceHandle: `e${params.source}-${sourceElement.id}-${randomizeIndex}`,
            targetHandle: `e${params.target}-${sourceElement.id}-${randomizeIndex}`,
          }
        } else {
          sourceElement.linkToId = params.target
        }

        updateElement({
          variables: sourceElement,
        }).then(() => {
          const oldNodeId = sourceElement.linkToId
          updateNodeInternals(sourceNode.id)
          updateNodeInternals(params.target)
          updateNodeInternals(oldNodeId)
        })
        setEdges((eds) => addEdge(newEdge, eds))
      }
    },
    [nodes, edges]
  )

  const onConnectStart = useCallback((_, { nodeId, handleId }) => {
    const { getEdges, getNodes, getNode } = reactFlow
    connectingNodeId.current = { nodeId, handleId }

    const element = getElementFromSourceHandle(reactFlow, nodeId, handleId)
    setConnectionLineStyle({
      ...connectionLineStyle,
      stroke: getPointColor(element?.points, scenario.scoringSystem.kind),
    })
    const edge = getEdges().find((e) => e.sourceHandle === handleId)
    if (edge) removeEdge(reactFlow, edge.id)

    const allHandles = getNodeHandles(
      getNodes(),
      getNode(nodeId).data,
      getEdges()
    )
    const sourceHandle = allHandles.source.find((h) => h.id === handleId)
    if (!sourceHandle) return false
    const { isRandomized, randomizeIndex } = sourceHandle
    if (isRandomized) element.randomizedLinkToIds[randomizeIndex] = ''

    if (element)
      updateElement({
        variables: {
          ...element,
          linkToId: null,
        },
      })
  }, [])

  const onConnectEnd = useCallback(
    (event) => {
      if (!connectingNodeId.current) return

      const targetIsPane = event.target.classList.contains('react-flow__pane')

      if (targetIsPane) {
        removeNewNodePlaceholder()
        showNewNode(event)

        const { nodeId, handleId } = connectingNodeId.current

        const element = getElementFromSourceHandle(reactFlow, nodeId, handleId)

        const newEdge = {
          id: newEdgeId,
          source: nodeId,
          sourceHandle: handleId,
          target: newNodeId,
          targetHandle: `e${newNodeId}`,
          type: 'default-edge',
          data: {
            points: element?.points,
          },
        }
        setEdges((eds) => addEdge(newEdge, eds))

        const connectedNodes = getConnectedNodes(reactFlow, element.linkToId)
        connectedNodes.forEach((nodeId) => updateNodeInternals(nodeId))
      }
    },
    [edges]
  )

  const showNewNode = useCallback(
    (e) => {
      const { y: offsetTop } = diagramRef.current.getBoundingClientRect()
      const maxX = diagramRef.current.clientWidth
      const maxY = diagramRef.current.clientHeight + offsetTop
      let { width, height } = config.newNodeDimensions
      const zoom = reactFlow.getZoom()
      width = width * zoom
      height = height * zoom
      addNewNodePlaceholder(
        screenToFlowPosition({
          x: e.clientX + width > maxX ? maxX - width - 10 : e.clientX,
          y: e.clientY + height > maxY ? maxY - height - 10 : e.clientY,
        }),
        setNodes,
        scenario.id
      )
    },
    [nodes]
  )

  const handlePaneClick = useDoubleClick(
    (e) => {
      showNewNode(e)
      connectingNodeId.current = null
    },
    () => {
      if (connectingNodeId.current) {
        connectingNodeId.current = null
        return
      } else {
        const event = new Event('reactflowPaneClick')
        document.dispatchEvent(event)
      }
    }
  )

  const isValidConnection = useCallback(
    (connection) => {
      // we are using getNodes and getEdges helpers here
      // to make sure we create isValidConnection function only once
      const target = nodes.find((node) => node.id === connection.target)

      // Check if the target node already has a connection
      const existingConnection = edges.find(
        (edge) => edge.targetHandle === connection.targetHandle
      )
      if (existingConnection) return false

      return target.id !== connection.source
    },
    [nodes, edges]
  )

  // gets called after end of edge gets dragged to another source or target
  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      removeEdge(reactFlow, oldEdge.id)
      onConnect(newConnection)
    },
    [nodes, edges]
  )

  const handleNodeDragStop = useCallback(
    (e, node, nodes) => {
      if (nodes.length) {
        nodes.forEach((chN) => {
          const node = nodes.find((n) => n.id === chN.id)
          const {
            position: { x: newX, y: newY },
          } = chN

          if (!Number(node.id)) {
            setNode(reactFlow, {
              ...node,
              xPos: parseInt(newX),
              yPos: parseInt(newY),
            })
            return false
          }
          updateSceneDetails(
            {
              ...node.data,
              description: node.data.description ?? '',
              canvasX: parseInt(newX),
              canvasY: parseInt(newY),
            },
            node
          )
        })
      }
    },
    [selectedNode]
  )

  const handleSelectionChange = useCallback(
    ({ nodes: selectedNodes }) => {
      if (fittedView && showFormEditor) {
        if (selectedNodes.length) {
          if (
            selectedNodes.length == 1 &&
            previousSelectedNode.current?.id !== selectedNodes[0].id
          ) {
            previousSelectedNode.current = selectedNodes[0]
            const match = location.pathname.includes(
              `/scenes/${selectedNodes[0].id}`
            )
            if (!match)
              navigate(
                `/scenes/${selectedNodes[0].id}${showVideoEditor ? '/video' : ''}`
              )
          }
        } else navigate(`/`)
      }
      previousSelectedNode.current = selectedNodes[0]
    },
    [fittedView, location.pathname, previousSelectedNode]
  )

  const handleMouseOver = useCallback(
    (e) => {
      const { target } = e
      if (target.classList.contains('react-flow__pane'))
        unSelectHoverEdges(reactFlow)
    },
    [reactFlow]
  )

  const updateCanvasPosition = useCallback(() => {
    saveCanvasState(reactFlow)
  }, [reactFlow])

  const checkAndReorderAllNodesElements = () => {
    if (!nodes.length) return false
    let reorderedNodeElements = []
    nodes.forEach((node) => {
      const el = checkAndReorderElements(node)
      if (el) reorderedNodeElements.push(el)
    })

    if (reorderedNodeElements.length) {
      reactFlow.setNodes([
        ...nodes.filter(
          (node) => !reorderedNodeElements.find((ne) => ne.id === node.id)
        ),
        ...reorderedNodeElements,
      ])
    }
  }

  useOnViewportChange({
    onEnd: updateCanvasPosition,
  })

  useFlowKeyboardShortcuts(reactFlow)

  useEffect(() => {
    const { scenes } = scenario
    scenesToNodes(reactFlow, scenes, location)
  }, [scenario])

  useEffect(() => {
    const { scenes } = scenario
    if (scenes.length && nodesInitialized && !fittedView) {
      setFittedView(true)

      checkAndReorderAllNodesElements()

      if (showFormEditor) {
        const match = location.pathname.match(SCENES_PATH_REGEX)
        const sceneId = match?.[1]
        const node = sceneId && nodes.find((n) => n.id === sceneId)
        if (node) {
          setTimeout(() => {
            setCentralNodeAndSelectIt(reactFlow, node, 1)
          }, 110)
        } else {
          const canvasState = getCanvasState()
          if (!canvasState) setTimeout(() => fitView(reactFlow), 100)
          else {
            reactFlow.setViewport({
              x: canvasState.x,
              y: canvasState.y,
              zoom: canvasState.zoom,
            })
          }
        }
      }
    }
  }, [location.pathname, fittedView, nodesInitialized])

  useEffect(() => {
    updateDocumentTitle(
      selectedNodes?.length === 1 ? `Scene ${selectedNodes[0].data.number}` : ''
    )
  }, [selectedNodes])

  return (
    <div
      id="flow-diagram"
      style={{
        visibility: visible ? 'visible' : 'hidden',
      }}
      ref={diagramRef}
      onMouseOver={handleMouseOver}>
      <ReactFlow
        proOptions={{ hideAttribution: true }}
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgeUpdate={onEdgeUpdate}
        onConnect={onConnect}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onSelectionChange={handleSelectionChange}
        onNodeDragStop={handleNodeDragStop}
        edgeTypes={edgeTypes}
        onPaneClick={handlePaneClick}
        zoomOnDoubleClick={false}
        deleteKeyCode={null}
        minZoom={config.minZoom}
        maxZoom={config.maxZoom}
        isValidConnection={isValidConnection}
        elevateEdgesOnSelect
        edgeUpdaterRadius={16}
        connectionLineStyle={connectionLineStyle}
        multiSelectionKeyCode="Shift"
        selectionKeyCode="Shift"
        selectionMode="partial"
        disableKeyboardA11y={true}
        onlyRenderVisibleElements={
          nodes.length > config.onlyRenderVisibleElementsThreshold
        }>
        <Background
          color="#ccc"
          variant="dots"
          gap={24}
          size={2}
          style={{ background: 'var(--main-bg-color)' }}
        />
        <FlowControls />
        <footer>Double-click anywhere to create a scene</footer>
      </ReactFlow>
    </div>
  )
}

export default FlowDiagram
