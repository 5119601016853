const clamp = (number, min, max) => {
  if (number < min) return min
  if (number > max) return max
  return number
}

const moveIndex = (array, fromIndex, toIndex) => {
  const item = array[fromIndex]
  const diff = fromIndex - toIndex

  if (diff > 0) {
    return [
      ...array.slice(0, toIndex),
      item,
      ...array.slice(toIndex, fromIndex),
      ...array.slice(fromIndex + 1, array.length),
    ]
  }
  if (diff < 0) {
    const targetIndex = toIndex + 1
    return [
      ...array.slice(0, fromIndex),
      ...array.slice(fromIndex + 1, targetIndex),
      item,
      ...array.slice(targetIndex, array.length),
    ]
  }
  return array
}

const capitalized = (string) => {
  if (!string) return null
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1)
}
const truncate = (value, length, showEllipsis) => {
  if (value.length > length) {
    if (showEllipsis) {
      if (value.length > length + 3) {
        return `${value.substring(0, length)}...`
      }
      return value
    }
    return value.substring(0, length)
  }
  return value
}

const chunkArray = (array, chunkSize) => {
  let index = 0
  const chunks = []
  for (index; index < array.length; index += chunkSize) {
    const chunk = array.slice(index, index + chunkSize)
    chunks.push(chunk)
  }
  return chunks
}
const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

const scrollIntoViewWithOffset = (element, headerOffset = 10) => {
  const elementPosition = element.offsetTop
  const scrollPosition = elementPosition - headerOffset

  const container = document.querySelector('.scroll-container')
  container.scrollTo({
    top: scrollPosition,
    behavior: 'smooth',
  })
}

const numberToCurrency = (price, unit) => {
  if (price === undefined) return '0'
  return new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: unit ?? 'EUR',
  }).format(price)
}

const cleanObjectFalsyValues = (obj, removeEmptyArray = false) =>
  Object.entries(obj).reduce(
    (a, [k, v]) =>
      v &&
      (!removeEmptyArray || !Array.isArray(v) || v.filter((vv) => !!vv).length)
        ? ((a[k] = v), a)
        : a,
    {}
  )

const isIntersecting = (min0, max0, min1, max1) => {
  return (
    Math.max(min0, max0) >= Math.min(min1, max1) &&
    Math.min(min0, max0) <= Math.max(min1, max1)
  )
}
const padZero = (v) => {
  if (v < 10) return `0${v}`
  return v
}

const htmlToText = (value) => {
  const div = document.createElement('div')
  div.innerHTML = value
  return div.textContent || div.innerText || ''
}

export {
  clamp,
  capitalized,
  moveIndex,
  truncate,
  chunkArray,
  getKeyByValue,
  scrollIntoViewWithOffset,
  numberToCurrency,
  cleanObjectFalsyValues,
  isIntersecting,
  padZero,
  htmlToText,
}
