import { BubbleMenu, EditorProvider, useCurrentEditor } from '@tiptap/react'
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from 'react'
import { FaCheckCircle, FaTimes } from 'react-icons/fa'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Placeholder from '@tiptap/extension-placeholder'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'

import ToolbarPlugin from '../../../UI/HtmlEditor/ToolbarPlugin'
import sanitizeHTML from '../../../../utils/helpers/sanitizeHTML'
import { htmlToText } from '../../../../utils/helpers/javascript'

const STORAGE_KEY = 'inputDrafts'

const EditorContent = ({ id, value, focused }) => {
  const { editor } = useCurrentEditor()

  useEffect(() => {
    if (editor) {
      editor.commands.clearContent()
      editor.commands.setContent(value ? value : '<p></p>')
    }
  }, [id, value])

  useEffect(() => {
    if (focused) editor.commands.focus('end')
  }, [focused])

  return (
    <BubbleMenu>
      <div className="bubble-menu">
        <ToolbarPlugin />
      </div>
    </BubbleMenu>
  )
}

const TransparentInput = forwardRef(
  (
    {
      id = '',
      onConfirm = () => {},
      onCancel = () => {},
      placeholder = 'Add a label...',
      className = '',
      minHeight = 37,
      focusedMinHeight = 37,
      value: defaultValue = '',
      multiline = false,
      style = {},
      useDraft = true,
      onTriggerFocus = () => {},
      type,
      ...props
    },
    outerRef
  ) => {
    const [hovered, setHovered] = useState(false)
    const [focused, setFocused] = useState(false)
    const [value, _setValue] = useState(defaultValue)
    const innerRef = useRef(null)
    const isMouseDownInside = useRef(null)
    const draftRef = useRef(null)
    useImperativeHandle(outerRef, () => innerRef.current, [])

    const setValue = (value) => {
      _setValue(value)
      if (type === 'htmlEditor' || !innerRef.current) return
      if (type === 'contentEditable') {
        innerRef.current.innerHTML = value ?? ''
      } else {
        value = htmlToText(value)
        _setValue(value)
        innerRef.current.value = value
      }
    }

    const handleCancel = () => {
      setValue(defaultValue)
      clearDraft()
      onCancel()
    }

    const handleConfirm = (e) => {
      e.stopPropagation()
      const value = ['contentEditable', 'htmlEditor'].includes(type)
        ? innerRef.current.innerHTML
        : innerRef.current.value
      clearDraft()
      onConfirm({
        target: { name: props.name, value },
      })
      setHovered(false)
      setFocused(false)
      setValue(value)
      innerRef.current.blur()
    }

    const loadDraft = () => {
      const draftValue = localStorage.getItem(STORAGE_KEY)
      return draftValue ? JSON.parse(draftValue) : {}
    }

    const saveDraft = () => {
      const draftValue = draftRef.current
      const draft = loadDraft()
      if (draft[id] === draftValue) return
      localStorage.setItem(
        STORAGE_KEY,
        JSON.stringify({ ...draft, [id]: draftValue })
      )
    }

    const restoreDraft = () => {
      const draft = loadDraft()[id]
      draftRef.current = draft
      if (draft) {
        if (type === 'htmlEditor') return setValue(draft)
        if (type === 'contentEditable') innerRef.current.innerHTML = draft
        else innerRef.current.value = draft
      }
    }

    const clearDraft = () => {
      const { ...draft } = loadDraft()
      draftRef.current = ''
      delete draft[id]
      localStorage.setItem(STORAGE_KEY, JSON.stringify({ ...draft }))
    }

    const handleClickOutside = () => {
      // return when user is selecting a text
      if (isMouseDownInside.current) return

      setFocused(false)
      if (innerRef.current) innerRef.current.blur()

      // double setting value to trigger re-render
      setValue('')
      setTimeout(() => {
        setValue(defaultValue)
      }, 0)
    }

    const renderInput = () => {
      const genericProps = {
        className: `border-0 border-radius mb-0 text-normal text-dark pl-1 tracking-tighter 
          ${hovered ? 'bg-stable-lightest' : ''} 
          ${focused ? 'bg-stable-lighter' : ''} 
          ${multiline ? 'pb-3' : ''}
          ${!hovered && !focused ? 'bg-transparent' : ''} 
          ${className}
        `,
        style: {
          ...(!multiline ? { paddingRight: '60px' } : {}),
          ...style,
        },
        ...props,
        ref: innerRef,
        onClick: (e) => {
          e.stopPropagation()
          setFocused(true)
        },
        onMouseDown: (e) => {
          isMouseDownInside.current = true
        },
        onMouseUp: (e) => {
          isMouseDownInside.current = false
        },
        onKeyPress: (e) => {
          if (e.key === 'Enter' && !multiline) {
            e.preventDefault()
            handleConfirm(e)
            return false
          }
        },
        onFocus: (e) => {
          setFocused(true)
          restoreDraft()
          const currentValue = ['contentEditable', 'htmlEditor'].includes(type)
            ? e.target.innerHTML
            : e.target.value
          props?.onFocus?.(currentValue)
        },
        onBlur: (e) => {
          const currentValue = ['contentEditable', 'htmlEditor'].includes(type)
            ? e.target.innerHTML
            : e.target.value
          props?.onBlur?.(currentValue)
        },
      }

      if (type === 'htmlEditor') {
        return (
          <EditorProvider
            content={sanitizeHTML(value)}
            extensions={[
              Bold,
              Italic,
              Document,
              Paragraph,
              Text,
              Underline,
              Placeholder.configure({
                placeholder: !value ? placeholder : '',
              }),
            ]}
            injectCSS={false}
            editable={true}
            autofocus={focused}
            editorContainerProps={{
              ...genericProps,
              style: {
                minHeight: `${focused ? focusedMinHeight : minHeight}px`,
                overflow: 'hidden',
                outline: 'none',
                padding: '0.5rem',
                transition: 'all 0.2s',
                wordBreak: 'break-word',
                cursor: 'text',
                ...genericProps.style,
              },
            }}
            onUpdate={({ editor }) => {
              const content = editor.getHTML()
              draftRef.current = content
              if (useDraft) saveDraft()
            }}>
            <EditorContent id={id} value={value} focused={focused} />
          </EditorProvider>
        )
      }

      if (type === 'contentEditable')
        return (
          <div
            {...genericProps}
            contentEditable="plaintext-only"
            suppressContentEditableWarning={true}
            data-placeholder={placeholder}
            tabIndex={100}
            style={{
              minHeight: `${focused ? focusedMinHeight : minHeight}px`,
              overflow: 'hidden',
              outline: 'none',
              padding: '0.5rem',
              transition: 'all 0.2s',
              wordBreak: 'break-word',
              ...genericProps.style,
            }}>
            {value}
          </div>
        )

      return (
        <input
          {...genericProps}
          type="text"
          onKeyUp={(e) => {
            if (e.key === 'Enter') handleConfirm(e)
          }}
          placeholder={placeholder}
          onChange={(e) => {
            draftRef.current = e.target.value
            if (useDraft) saveDraft()
          }}
        />
      )
    }

    useEffect(() => {
      setTimeout(() => {
        setValue(defaultValue)
      }, 200)
    }, [defaultValue])

    useEffect(() => {
      onTriggerFocus(focused)
      if (focused) document.body.addEventListener('click', handleClickOutside)
      else
        setTimeout(() => {
          document.body.removeEventListener('click', handleClickOutside)
        }, 500)

      return () => {
        document.body.removeEventListener('click', handleClickOutside)
      }
    }, [focused])

    useEffect(() => {
      setFocused(false)
    }, [id])

    return (
      <div
        className="w-100 h-100"
        style={{ position: 'relative', maxHeight: '100%' }}
        hidden={props.hidden}
        onMouseEnter={setHovered}
        onMouseLeave={() => {
          setHovered(false)
        }}>
        <div
          style={{
            position: 'absolute',
            right: '10px',
            bottom: `${['contentEditable', 'htmlEditor'].includes(type) === 'contentEditable' ? 1 : 0}px`,
          }}
          className="bg-stable-lighter pl-0-5 text-xxlarge"
          hidden={!focused}>
          <button
            onClick={handleCancel}
            className="text-stable-dark cursor-pointer">
            <FaTimes />
          </button>
          <button
            onClick={handleConfirm}
            className="text-assertive ml-1 cursor-pointer">
            <FaCheckCircle />
          </button>
        </div>
        {renderInput()}
      </div>
    )
  }
)

export default TransparentInput
