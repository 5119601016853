import { useEffect, useRef, useContext } from 'react'
import useAddUpdateScene from './useAddUpdateScene'
import useAddUpdateElement from './useAddUpdateElement'
import {
  useNodesInitialized,
  useReactFlow,
  useUpdateNodeInternals,
} from 'reactflow'
import { scenesToEdges } from '../helpers/edgeHelper'
import { ScenarioEditorContext } from '../context/ScenarioEditorProvider'
import { useLazyQuery, useMutation } from '@apollo/client'
import { getMediaByScenarioIdQuery } from '../../../apollo/query/media'
import {
  getSceneWithElementsQuery,
  updateElementMutation,
  updateLinkMediumToSceneMutation,
} from '../../../apollo/query/scenes'
import { handleApolloError } from '../../../utils/errors'
import { fillElementDefaultValues } from '../helpers/elementHelper'

const useDuplicateNodes = () => {
  const reactFlow = useReactFlow()
  const { addScene } = useAddUpdateScene()
  const { addElement } = useAddUpdateElement()
  const { setNodes, addEdges } = reactFlow
  const updateNodeInternals = useUpdateNodeInternals()
  const [updateElement] = useMutation(updateElementMutation, {
    onError: handleApolloError,
  })

  const {
    scenario: { id: scenarioId },
  } = useContext(ScenarioEditorContext)
  const newScenesRef = useRef([])

  const [getMedias] = useLazyQuery(getMediaByScenarioIdQuery, {
    variables: {
      scenarioId: scenarioId,
    },
  })

  const [updateLinkMediumToScene] = useMutation(
    updateLinkMediumToSceneMutation,
    {
      onError: handleApolloError,
    }
  )

  const [reloadScene] = useLazyQuery(getSceneWithElementsQuery, {
    fetchPolicy: 'no-cache',
  })

  const duplicateNodes = async (nodes) => {
    const scenes = nodes.map((node) => ({
      name:
        node.data.name === `Scene ${node.data.number}` ? '' : node.data.name,
      description: node.data.description,
      start: false,
      canvasX: node.position.x + 100,
      canvasY: node.position.y + 100,
      scenarioId,
      selected: false,
    }))
    const responses = []

    const medias = nodes.some((n) => n.data.hasVideo) ? await getMedias() : null

    for (let index = 0; index < scenes.length; index++) {
      // we couldn't use Promise.all here to add scene because of scene numbers
      let newScene = await addScene(scenes[index])

      // connect media if exist
      const oldNode = nodes[index]
      if (oldNode.data.hasVideo) {
        const media = medias.data.media.find((m) =>
          m.linkedToScenes.find((s) => s.id === oldNode.id)
        )
        if (media) {
          const result = await updateLinkMediumToScene({
            variables: {
              mediumId: media.id,
              sceneId: newScene.id,
            },
          })
          newScene = {
            ...result.data.scene,
            ...newScene,
          }
        }
      }

      responses.push(newScene)
    }
    newScenesRef.current = responses.map((scene, index) => ({
      ...scene,
      oldElements: nodes[index].data.elements,
      elements: [],
      oldSceneId: nodes[index].id,
    }))
  }

  const nodesInitialized = useNodesInitialized()

  useEffect(() => {
    async function duplicateElements() {
      if (nodesInitialized && newScenesRef.current?.length) {
        const newScenes = newScenesRef.current
        newScenesRef.current = null
        const nodesToBeUpdated = []
        const nodes = reactFlow.getNodes()
        const scenesToBeUpdated = newScenes.filter((node) =>
          nodes.find((n) => n.id === node.id)
        )
        if (scenesToBeUpdated.length) {
          await Promise.all(
            scenesToBeUpdated.map(async (scene, index) => {
              const node = nodes.find((n) => n.id === scene.id)
              let elements = []
              for (let index = 0; index < scene.oldElements.length; index++) {
                const oldElement = scene.oldElements[index]
                const {
                  data: {
                    addElement: { element },
                  },
                } = await addElement(
                  {
                    variables: {
                      ...oldElement,
                      sceneId: scene.id,
                    },
                  },
                  node,
                  true
                )
                
                const linkToId = newScenes.find(
                  (s) => s.oldSceneId === oldElement.linkToId
                )?.id
                const newElement = {
                  ...oldElement,
                  id: element.id,
                  linkToId,
                }
                updateElement({
                  variables: fillElementDefaultValues(newElement),
                })
                elements.push(newElement)
              }
              nodesToBeUpdated.push({
                ...node,
                data: {
                  ...scene,
                  elements,
                },
              })
              scenesToBeUpdated[index].elements = elements
            })
          )
          setNodes(
            nodes.map((n) => nodesToBeUpdated.find((nu) => nu.id === n.id) ?? n)
          )
          const newEdges = scenesToEdges(scenesToBeUpdated)
          addEdges(newEdges)
        }

        Promise.all(
          newScenes.map((scene) =>
            reloadScene({
              variables: {
                id: scene.id,
              },
            })
          )
        ).then((responses) => {
          const nodesToBeUpdated = [
            ...nodes.filter(
              (n) => !responses.find((r) => r.data.scene.id === n.id)
            ),
            ...responses.map((r) => {
              const scene = r.data.scene
              const node = nodes.find((n) => n.id === scene.id)
              return {
                ...node,
                data: {
                  ...node.data,
                  ...r.data.scene,
                  elements: newScenes.find((s) => s.id === scene.id).elements,
                },
              }
            }),
          ]
          setNodes(nodesToBeUpdated)

          nodesToBeUpdated.forEach((node) => updateNodeInternals(node.id))
        })
      }
    }

    duplicateElements()
  }, [nodesInitialized, newScenesRef.current])

  return duplicateNodes
}

export default useDuplicateNodes
